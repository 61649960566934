import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore'; // Assuming you have imported firestore correctly
import { db } from '../firebase-config'; // Assuming you have set up your Firebase configuration
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/Loading';
// import './Designs/updatetask.css'




const TaskUpdate = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null); // State to track the selected task
  const [selectedTask1, setSelectedTask1] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state


  async function fetchTasks() {
    setIsLoading(true);
    const querySnapshot = await getDocs(collection(db, "TaskUpdate"));
    const tasksData = [];
    querySnapshot.forEach((doc) => {
      tasksData.push({ id: doc.id, data: doc.data() });
    });
    setTasks(tasksData);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchTasks();
  }, []);


  const openLargeModal = (task) => {
    setSelectedTask(task);
    setModalIsOpen(true);
  };

  const opensmallModal = (task) => {
    setSelectedTask1(task);
    setModalIsOpen1(true);
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = selectedTask1.data.photoURL; // Use the appropriate field for the file URL
    link.download = `Task_${selectedTask1.id}.${getFileExtension(selectedTask1.data.photoURL)}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2); // Extract file extension
  };

  return (
    <>

      {isLoading ?
        (<Loading />) :

        (
          <div className="card_list" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>
            {tasks.map((task, index) => (

              <Card key={index} style={{ width: '18rem' }}>
                <Card.Img variant="top" src={task.data.userpic} alt={`Photo of` & task.data.username} style={{ height: '18rem' }} />
                <Card.Body>
                  <Card.Title>{task.data.username}</Card.Title>

                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>Task Title: {task.data.Tasktitle}</ListGroup.Item>
                  <ListGroup.Item>Task Status: {task.data.status} 2</ListGroup.Item>
                  <ListGroup.Item>Volunteer Phone Number: {task.data.userphone}</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Button variant="primary" onClick={() => openLargeModal(task)}>
                    Details Button</Button>
                </Card.Body>
              </Card>

            ))}
          </div>
        )}

      {selectedTask1 && (
        <Modal
          size="xxl"
          show={modalIsOpen1}
          onHide={() => setModalIsOpen1(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body size="xl">
            <Button variant="primary" onClick={downloadFile}>
              Download File
            </Button>
          </Modal.Body>
        </Modal>
      )}



      {selectedTask && (
        <Modal
          size="lg"
          show={modalIsOpen}
          onHide={() => setModalIsOpen(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h2>{selectedTask.data.Taskid}</h2>
              <h2>Description: {selectedTask.data.additionalDetails}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <button onClick={() => opensmallModal(selectedTask)}>Click Here to Dowload Details file</button>
          </Modal.Body>

          <Modal.Body><a
            href={`https://www.google.com/maps/place/${selectedTask.data.location.latitude},${selectedTask.data.location.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Location of Volunteer
          </a></Modal.Body>
        </Modal>)}
    </>
  );
};

export default TaskUpdate;
