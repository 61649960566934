import React from "react";
import { db } from "../firebase-config.js";
import { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc, addDoc } from "firebase/firestore";
import "./Designs/Show.css";
import { Dist } from "../../Data/district.js";
import { GP } from "../../Data/gp.js";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { cons } from "../../Data/const.js";
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import PersonIcon from '@mui/icons-material/Person';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Loading from "../../components/Loading.js";
import { useHistory } from "react-router-dom"; // Import useHistory
import Modal from 'react-bootstrap/Modal';
import 'firebase/database';
import firebase from "firebase/compat/app";


const ShowAdmin = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedGP, setSelectedGP] = useState("");
  const [selectedCons, setSelectedCons] = useState("");
  const [selectBlock, setSelectedBlock] = useState("");
  const [deletedTask, setDeletedTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const history = useHistory(); // Get the history object
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('');
  const [newStatus, setNewStatus] = useState('');


  async function fetchTasks() {
    setIsLoading(true);
    const querySnapshot = await getDocs(collection(db, "VolunteerTask"));
    const tasksData = [];
    querySnapshot.forEach((doc) => {
      tasksData.push({ id: doc.id, data: doc.data() });
    });
    setTasks(tasksData);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firebase.database().ref('status').once('value');
        const statusData = snapshot.val();
        if (statusData) {
          const statusArray = Object.values(statusData);
          setData(statusArray);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleUpdateStatus = () => {
    if (newStatus.trim() === '') {
      return;
    }

    const statusToUpdate = {
      status: newStatus,
      timestamp: new Date().getTime(),
    };

    firebase.database().ref('status').push(statusToUpdate, (error) => {
      if (error) {
        console.error('Error updating status:', error);
      } else {
        setNewStatus('');
      }
    });
  };

  const handleDeleteStatus = (statusKey) => {
    firebase.database().ref(`status/${statusKey}`).remove((error) => {
      if (error) {
        console.error('Error deleting status:', error);
      }
    });
  };

  async function deleteTask(taskId) {
    const taskToDelete = tasks.find((task) => task.id === taskId);
    setDeletedTask(taskToDelete);

    await deleteDoc(doc(db, "VolunteerTask", taskId));
    fetchTasks();
  }

  async function undoDelete() {
    if (deletedTask) {
      await addDoc(collection(db, "VolunteerTask"), deletedTask.data);
      setDeletedTask(null);
      fetchTasks();
    }
  }

  async function confirmDelete(taskId) {
    const confirmed = window.confirm("Are you sure you want to delete this task?");
    if (confirmed) {
      deleteTask(taskId);
    }
  }


  const openModal = () => {
    setModalIsOpen(true);
  };



  const filterTasks = () => {
    const filteredTasks = tasks.filter((element) => {
      const districtMatch = selectedDistrict
        ? (element.data.District && typeof element.data.District === 'string' && element.data.District.split(',').includes(selectedDistrict))
        : true;
      const gpMatch = selectedGP
        ? (element.data.GP && typeof element.data.GP === 'string' && element.data.GP.split(',').includes(selectedGP))
        : true;
      const blockMatch = selectBlock
        ? (element.data.block && typeof element.data.block === 'string' && element.data.block.split(',').includes(selectBlock))
        : true;
      const consMatch = selectedCons
        ? (element.data.Cons && typeof element.data.Cons === 'string' && element.data.Cons.split(',').includes(selectedCons))
        : true;

      return districtMatch && gpMatch && blockMatch && consMatch;
    });

    return filteredTasks;
  };



  return (
    <div>
      <div className="heading">
        <h1 className="head">Welcome to Task Management System</h1>
      </div>
      <div className="optchose">
        <div>
          <label id="label">District </label>
          <select
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}
          >
            <option value="">All</option>
            {Dist.map((district) => (
              <option key={district.name} value={district.name}>
                {district.name}
              </option>
            ))}
            {/* Add more options for districts */}
          </select>

        </div>
        <div>
          <label id="label">Block </label>
          <select
            value={selectBlock}
            onChange={(e) => setSelectedBlock(e.target.value)}
          >
            <option value="">All</option>
            {Dist.find((district) => district.name === selectedDistrict)?.blocks.map((block) => (
              <option key={block} value={block}>
                {block}
              </option>
            ))}
            {/* Add more options for districts */}
          </select>
        </div>

        <div>
          <label id="label">Grampanchayat </label>
          <select
            value={selectedGP}
            onChange={(e) => setSelectedGP(e.target.value)}
          >
            <option value="">All</option>
            {GP.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label id="label">Consituency </label>
          <select
            value={selectedCons}
            onChange={(e) => setSelectedCons(e.target.value)}
          >
            <option value="">All</option>
            {cons.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div>
          <Button size="sm" className="button_hover" variant="Warning" 
            onClick={() => history.push('/UpdatedTask')}><UpdateIcon />
            Update Task</Button>{' '}
        </div>
        <div>
          <Button size="sm" className="button_hover" variant="Warning"  onClick={() => history.push('/newtask')}><AddIcon />
            New Task</Button>{' '}
        </div>

        <div>
          <Button size="sm" className="button_hover" variant="Warning" onClick={() => history.push('/memberdata')}>
            Member <PersonIcon /></Button>{' '}
        </div>

        <div>
          <Button size="sm" className="button_hover" variant="Warning"  onClick={() => openModal()}> Status
            <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" stroke="#22c55e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M4 18a2 2 0 1 0 4 0a2 2 0 1 0-4 0m12 0a2 2 0 1 0 4 0a2 2 0 1 0-4 0M6 12v-2a6 6 0 1 1 12 0v2"></path>
                <path d="m15 9l3 3l3-3"></path>
              </g>
            </svg></Button>
        </div>

        <div>
          <Button variant="outline-primary" size="sm" onClick={() => history.push('/volunterdata')}>
            <svg width="1em" height="1em" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
              <path fill="#000000" d="M740 1461q65 33 117 81t90 108t57 128t20 142H896q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149H0q0-73 20-141t57-128t89-108t118-82q-74-55-115-136t-41-173q0-79 30-149t82-122t122-83t150-30q92 0 173 41t136 115q38-75 97-134t134-97q-74-55-115-136t-41-173q0-79 30-149t82-122t122-83t150-30q79 0 149 30t122 82t83 123t30 149q0 92-41 173t-115 136q65 33 117 81t90 108t57 128t20 142h-128q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149q0 92-41 173t-115 136zm284-1077q0 53 20 99t55 82t81 55t100 20q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100zm-768 768q0 53 20 99t55 82t81 55t100 20q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100zm1024 256h768v128h-768v-128zm0 384v-128h768v128h-768zm0 256v-128h768v128h-768z"></path>
            </svg>
          </Button>
        </div>
      </div>

      {isLoading ?
        (
          <Loading />) :
        (
          filterTasks().map((element, index) => (
            <div className="task_card_container" key={index}>
              <div className="task_card">
                <Card className="text-center" border="danger" variant="flush">
                  <Card.Header><h2>TASK - {element.data.id}</h2></Card.Header>
                  <Card.Body>
                    <Card.Title><h2>Title - {element.data.Title}</h2></Card.Title>
                    <Card.Text>
                      <h5>Description - {element.data.Description}</h5>

                    </Card.Text>

                  </Card.Body>

                  <Card.Footer className="text-muted"><Card.Text>
                    <div className="row">
                      <div className="col">
                        <h6>District - {element.data.District}</h6>
                      </div>
                      <div className="col">
                        <h6>GP - {element.data.GP}</h6>
                      </div>
                      <div className="col">
                        <h6>Block - {element.data.Block}</h6>
                      </div>
                      <div className="col">
                        <h6>Consituency - {element.data.Cons}</h6>
                      </div>
                    </div>
                  </Card.Text>

                  </Card.Footer>
                  <Card.Footer className="text-muted" ><Button variant="danger" onClick={() => confirmDelete(element.id)} color="red">
                    <DeleteOutlineRoundedIcon />Delete</Button></Card.Footer>
                  <Card.Footer className="text-muted">DATE- {element.data.Date}</Card.Footer>
                </Card>
              </div>
            </div>
          ))
        )
      }

      {deletedTask && (
        <div>
          <p>Task deleted. Undo?</p>
          <button onClick={undoDelete}>Undo</button>
        </div>
      )}
      <Modal
        className='sm'
        show={modalIsOpen}
        onHide={() => setModalIsOpen(false)}
        size="lg"
      >
        <Modal.Header closeButton style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h1>Status Updates</h1>

          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{}}>
          <div style={{width:'100%'}}>
            <ul style={{display:'flex', justifyContent:'space-between',flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
              {data.map((statusItem, index) => (
                <li key={index} style={{display:'flex',flexDirection:'row', justifyContent:'space-around'}}>
                  <span><h2>{statusItem.status}</h2>
                  </span>
                  <button onClick={() => handleDeleteStatus(Object.keys(data)[index])} className="delete_button_hover"> <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                    <path fill="#ef4444" d="m20.37 8.91l-1 1.73l-12.13-7l1-1.73l3.04 1.75l1.36-.37l4.33 2.5l.37 1.37l3.03 1.75M6 19V7h5.07L18 11v8a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2Z"></path>
                  </svg></button>
                </li>
              ))}
            </ul>
            
          </div>
        </Modal.Body>
        <Modal.Footer >
        <div style={{display:'flex', flexDirection:'row' , justifyContent:'space-between', width:'100%'}}>
              <input
                type="text"
                placeholder="Enter status..."
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              />
              <button onClick={handleUpdateStatus} className="update_status_button">Update Status</button>
            </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShowAdmin;
