import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`


*{
  padding:0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
 
}

html{
  font-size: 62.5%;

}

body{

  background-color: #EFEDEE; 
   
}

h1{
  font-size:2.6rem;
}

p{
  font-size: 1.6rem;
}


`;