import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Designs/Navbar.css";


const NavBar = ({ toggleLanguage, lang }) => {



  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
        <Container className="Container">
          <Navbar.Brand href="#">
            <div className="plogo">
              <a href="index.html" style={{ textDecoration: 'none', color: 'black' }}>
                <h2>Samruddha Odisha</h2>
              </a>
            </div>
          </Navbar.Brand>
          {/* <div style={{display:'flex', flexDirection:'row-reverse'}}> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div className="space">
            <Navbar.Collapse className="navbarScroll">
              <Nav
                className="ml-auto my-2 my-lg-0"
                style={{ maxHeight: "500px" }}
                navbarScroll>
                <button
                  style={{ border: "none", background: "transparent", color: "black", transition: "color 0.2s", justifyContent: 'center' }}
                  onClick={toggleLanguage}
                  className="text-end"
                  onMouseEnter={(e) => (e.target.style.color = "blue")} // Change to your desired hover color
                  onMouseLeave={(e) => (e.target.style.color = "red")}>
                  {lang === "en" ? "ଓଡିଆ" : "ENG"}
                </button>
                <Nav.Link href="/" className="text-end" style={{ border: "none", background: "transparent", color: "black", transition: "color 0.2s", display: 'flex', justifyContent: 'center' }} onMouseEnter={(e) => (e.target.style.color = "blue")} // Change to your desired hover color
                  onMouseLeave={(e) => (e.target.style.color = "black")} >Home</Nav.Link>
                <Nav.Link href="/NewMember" className="text-end" style={{ border: "none", background: "transparent", color: "black", transition: "color 0.2s", display: 'flex', justifyContent: 'center' }} onMouseEnter={(e) => (e.target.style.color = "blue")} // Change to your desired hover color
                  onMouseLeave={(e) => (e.target.style.color = "black")}>Member</Nav.Link>
                <Nav.Link href="/Donation" className="text-end" style={{ border: "none", background: "transparent", color: "black", transition: "color 0.2s", display: 'flex', justifyContent: 'center' }} onMouseEnter={(e) => (e.target.style.color = "blue")} // Change to your desired hover color
                  onMouseLeave={(e) => (e.target.style.color = "black")}>Donation</Nav.Link>
                <Nav.Link href="/Volunter" className="text-end" style={{ border: "none", background: "transparent", color: "black", transition: "color 0.2s", display: 'flex', justifyContent: 'center' }} onMouseEnter={(e) => (e.target.style.color = "blue")} // Change to your desired hover color
                  onMouseLeave={(e) => (e.target.style.color = "black")}>Volunteer Login</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>




    </>
  );
};

export default NavBar;
