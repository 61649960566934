import React from 'react';
// import load from './loading.gif'
import Spinner from 'react-bootstrap/Spinner';
const Loading = () => {


    return (
        <div className='text-center'>
        {/* //     <img src={load} alt='loading' /> */}
       

        <Spinner animation="border" role="status" size="xl" >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
       </div>
    );
};

export default Loading;