import React, { useEffect, useState } from "react";
import slogo1 from "../images/logo.jpeg";
import flogo1 from "../images/founder.jpeg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import card1pic from "../images/beAMember.jpeg";
import card2pic from "../images/beavolnter.jpeg";
import card3pic from "../images/donate.jpeg";
import { useHistory } from 'react-router-dom';
import CounterAnimation from "../components/counter";
import Footer from "../components/Footer";
import "./Designs/Home.css"
import { useTranslation } from "react-i18next";
import ChangingTextComponent from "../components/ChangingTextComponent";
import { Helmet } from "react-helmet-async";
import CardGallery from "../components/Image-gallery";

function Home() {

  const opts = {
    height: '100%', // Set height to 100% to maintain aspect ratio
    width: '1000px', // Set width to 100% to make it responsive

  };

  const { t } = useTranslation();   // const [language, setLanguage] = useState("en");
  const [clickCount, setClickCount] = useState(0);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to know more
    </Tooltip>
  );

  const history = useHistory();

  const handleMemberClick = () => {
    history.push('/NewMember');
  };

  const handleDonateClick = () => {
    history.push('/Donation');
  };

  const handlevolunterClick = () => {
    history.push('/Login');
  };

  const handleClick = () => {
    setClickCount(clickCount + 1);

    if (clickCount === 4) { // 4 because it's zero-based
      // You can replace this with the action you want to perform
      // For example, navigating to a new page using React Router
      history.push('/admin');
    }
  };

  return (

    <>
      <div className="backgr_ound">

        <Helmet>
          <title>Samruddha Odisha | Home</title>
          <meta
            name='description'
            content='Welcome to Samruddha Odisha Political Party'
          ></meta>
        </Helmet>

        {/* First welcome part  */}
        <div className="outer1">
          <h1 className="headert1">{t("home.home.title")}</h1>
          <p onClick={handleClick}>
            <h2 className="headert2">{t("home.home.title1")}</h2>
          </p>
          <h2 className="headert3">{t("home.home.title2")}</h2>
        </div>

        {/* <ImageSlider /> */}

        {/* Main */}
        <div className="main">
          <div class="outer2">
            <p>
              {t("home.home.about")}
            </p>
          </div>
          <br></br>


          {/* party logo in right */}
          <div class="text1" id="one">
            <img src={slogo1} className="ima1"></img>
          </div>
        </div>

        {/* Jatish  */}

        <div className="main1">
          <div class="outer4">
            <p>
              {t("home.home.leader")}
            </p>
            <p>

            </p>
          </div>
          <div class="text1" id="two">
            <img src={flogo1} className="ima2"></img>
          </div>



          <div className="hoverbutton">
            <a href="https://en.wikipedia.org/wiki/Jatish_Chandra_Mohanty">
              <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 50 }}
                overlay={renderTooltip}
              >
                <Button variant="success">Jatish chandra Mohanty </Button>
              </OverlayTrigger>
            </a>
          </div>
        </div>

        {/* Accommodations */}
        <div className="outer3">
          <h1 className="o3h1">Join the Momvent</h1>
        </div>

        <div className="secondmain">
          <div className="secondmain1">
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={card1pic} className="text1" id="three" />
              <Card.Body>
                <Card.Title>Member</Card.Title>
                <Card.Text>
                  We are so excited you have joined our team! With your experience, you are going to be a great addition. Welcome!
                </Card.Text>
                <Button variant="outline-primary" className="joinasmember" onClick={handleMemberClick} >
                  Join As A Member
                </Button>{""}
              </Card.Body>
            </Card>
          </div>

          <div className="secondmain2">
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={card3pic} className="text1" id="three" />
              <Card.Body>
                <Card.Title>Donation</Card.Title>
                <Card.Text>
                  Your donation puts a smile on us.
                </Card.Text>
                <Button variant="outline-primary" className="joinasmember" onClick={handleDonateClick}>
                  Donate
                </Button>{" "}
              </Card.Body>
            </Card>
          </div>

          <div className="secondmain3">
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={card2pic} className="text1" id="three" />
              <Card.Body>
                <Card.Title>Volunter</Card.Title>
                <Card.Text>
                  We cannot live only for ourselves. A thousand fibers connect us with our fellow men.
                </Card.Text>
                <Button variant="outline-primary" className="joinasmember" onClick={handlevolunterClick}>
                  Be A Volunter
                </Button>{" "}
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="Img-gallery">
          <CardGallery />
        </div>



        <div className="youtube">

          <p className="yt_text">୨୨ ବର୍ଷ ର  ଶାସନ ପରେ....ଓଡିଶା ର ସ୍ଥିତି ଏବେ କେଉଠି,
            *ନିଜ ଅଧିକାର ପାଇଁ...
            ଆଗାମୀ ପିଢ଼ିର ଭବିଷ୍ୟତର ସୁରକ୍ଷା ପାଇଁ....
            ମା ଓ ମାଟିର ମୁକ୍ତି ପାଇଁ...
            ପରିବର୍ତ୍ତନ ପାଇଁ....।*
            ଆସନ୍ତୁ, ନିଜ ଅଞ୍ଚଳ ବିକାଶର ନେତୃତ୍ୱ ନିଅନ୍ତୁ। ଆଗାମୀ ବିଧାନସଭା ନିର୍ବାଚନରେ ସମୃଦ୍ଧ ଓଡିଶା ଦଳର ପ୍ରାର୍ଥୀ ହୁଅନ୍ତୁ।
            ଯୋଗ ଦେବା ପାଇଁ
            <a href="/newmember">ଏଠାରେ👇କ୍ଲିକ୍ କରନ୍ତୁ...</a>
          </p>


          <iframe src="https://www.youtube.com/embed/vxGDQVQnaa4" title="ସମୃଦ୍ଧ ଓଡିଶା ଦଳର ପ୍ରାର୍ଥୀ ହୁଅନ୍ତୁ। http://samruddhaodisha.in/" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </div>

        <div className="quote">
          <ChangingTextComponent />
        </div>
        <CounterAnimation />
        <Footer />

      </div>
    </>
  );
}

export default Home;