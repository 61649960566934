import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from '../Pages/firebase-config';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  // Function to register a user using mobile number
  const registerUser = async (phoneNumber, recaptchaVerifier) => {
    try {
      const confirmationResult = await firebase.auth().signInWithPhoneNumber(
        phoneNumber,
        recaptchaVerifier
      );
      return confirmationResult;
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  };

  // Function to verify OTP code and complete authentication
  const verifyOTPAndSignIn = async (confirmationResult, otp) => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        otp
      );
      await firebase.auth().signInWithCredential(credential);
    } catch (error) {
      console.error('Error verifying OTP and signing in:', error);
      throw error;
    }
  };

  // Function to fetch user data based on UID
  const fetchUserData = async (uid) => {
    try {
      const userDocRef = firebase.firestore().collection('users').doc(uid);
      const userDoc = await userDocRef.get();
      if (userDoc.exists) {
        return userDoc.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, registerUser, verifyOTPAndSignIn, fetchUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
