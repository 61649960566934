import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


const AdminLogin = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoggedIn(username, password);
    history.push('/TaskManagement')
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  return (
    <>
      <div className='background'>
        <div className="login-page" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '50px' }}>
          {/* {loggedIn ? (
            <div className="logged-in-container">
              <h1>Welcome, {username}!</h1>
              <button onClick={handleLogout}>Logout</button>
            </div>
          ) : ( */}


          <Card style={{ width: '28rem', height: '15rem', display: 'flex', justifyContent: 'center' }}>
            <Card.Header style={{ display: 'flex', justifyContent: 'center' }}>
              <h1>Login</h1>
            </Card.Header>
            <Card.Body style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Card.Title style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Card.Title>


            </Card.Body>
            <Card.Footer className="text-muted" style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="warning" onClick={handleLogin}>Login</Button>
            </Card.Footer>
          </Card>
        </div>
      </div>


    </>

  );
};

export default AdminLogin;



