import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import ExcelJS from 'exceljs';
import './Designs/tablestyle.css';
import Loading from '../../components/Loading';
import { Dist } from '../../Data/district';
import { GP } from '../../Data/gp';
import { cons } from '../../Data/const';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const TableComponent = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedGP, setSelectedGP] = useState('');
  const [selectBlock, setSelectedBlock] = useState('');
  const [selectedCons, setSelectedCons] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userCollection = db.collection('Members');
        const snapshot = await userCollection.get();
        const usersData = [];


        let index = 1;

        snapshot.forEach((doc) => {
          const { MemberId, MemberCategory, Date, Name, Father, Block, Constituency, Dist, MobileNo, Village, GramPanchayat, PIN } = doc.data();
          usersData.push({
            MemberId,
            MemberCategory,
            Date,
            Name,
            Father,
            Block,
            Constituency,
            Dist,
            MobileNo,
            Village,
            GramPanchayat,
            PIN
          });
        });

        setUserData(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  const filterTasks = () => {
    const filteredTasks = userData.filter((user) => {
      const districtMatch = selectedDistrict
        ? user.Dist === selectedDistrict
        : true;
      const gpMatch = selectedGP ? user.GramPanchayat === selectedGP : true;
      const blockMatch = selectBlock ? user.Block === selectBlock : true;
      const consMatch = selectedCons
        ? user.Constituency === selectedCons
        : true;

      return districtMatch && gpMatch && blockMatch && consMatch;
    });

    return filteredTasks;
  };

  const handleDownloadExcel = async () => {
    const filteredData = filterTasks(); // Get filtered data
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('FilteredUserData');
  
    // Define column headers (if needed)
    worksheet.addRow([
      'Date Of Join',
      'Member ID',
      'Member Name',
      'Father Name',
      'Mobile No.',
      'Member Category',
      'Village',
      'Gram Panchayat',
      'Block',
      'Constituency',
      'District',
      'PIN Code',
    ]);
  
    // Add filtered data rows
    filteredData.forEach((user) => {
      worksheet.addRow([
        user.Date,
        user.MemberId,
        user.Name,
        user.Father,
        user.MobileNo,
        user.MemberCategory,
        user.Village,
        user.GramPanchayat,
        user.Block,
        user.Constituency,
        user.Dist,
        user.PIN,
      ]);
    });
  
    // Create a Blob with the filtered workbook data
    const blob = await workbook.xlsx.writeBuffer();
  
    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'MemberData.xlsx';
    a.click();
  
    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  };
  





  if (loading) {
    return <div>
      <Loading />
    </div>;
  }

  return (
    <div>
      <div className="optchose">
        <div>
          <label id="label">District </label>
          <select
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}
          >
            <option value="">All</option>
            {Dist.map((district) => (
              <option key={district.name} value={district.name}>
                {district.name}
              </option>
            ))}
            {/* Add more options for districts */}
          </select>

        </div>
        <div>
          <label id="label">Block </label>
          <select
            value={selectBlock}
            onChange={(e) => setSelectedBlock(e.target.value)}
          >
            <option value="">All</option>
            {Dist.find((district) => district.name === selectedDistrict)?.blocks.map((block) => (
              <option key={block} value={block}>
                {block}
              </option>
            ))}
            {/* Add more options for districts */}
          </select>
        </div>

        <div>
          <label id="label">Grampanchayat </label>
          <select
            value={selectedGP}
            onChange={(e) => setSelectedGP(e.target.value)}
          >
            <option value="">All</option>
            {GP.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
            {/* Add more options for GPs */}
          </select>
        </div>

        <div>
          <label id="label">Consituency </label>
          <select
            value={selectedCons}
            onChange={(e) => setSelectedCons(e.target.value)}
          >
            <option value="">All</option>
            {cons.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Date Of join</th>
            <th>Member ID</th>
            <th>Member Name</th>
            <th>Father Name</th>
            <th>Mobile No.</th>
            <th>Member Category</th>
            <th>Village</th>
            <th>Gram Panchayat</th>
            <th>Block</th>
            <th>Constituency</th>
            <th>District</th>
            <th>PIN Code</th>

          </tr>
        </thead>

        <tbody>
          {filterTasks().map((user) => (
            <tr key={user.MemberId}>
              <td>{user.Date}</td>
              <td>{user.MemberId}</td>
              <td>{user.Name}</td>
              <td>{user.Father}</td>
              <td>{user.MobileNo}</td>
              <td>{user.MemberCategory}</td>
              <td>{user.Village}</td>
              <td>{user.Block}</td>
              <td>{user.GramPanchayat}</td>
              <td>{user.Constituency}</td>
              <td>{user.Dist}</td>
              <td>{user.PIN}</td>

            </tr>
          ))}
        </tbody>
      </table>

      {/* <button onClick={handleDownloadExcel}>Download Excel</button> */}
      <div className="centered">
        <button onClick={handleDownloadExcel}>
          <FontAwesomeIcon icon={faDownload} /> Download Excel
        </button>
      </div>
    </div>
  );
};

export default TableComponent;
