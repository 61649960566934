import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database'


const firebaseConfig = {
  apiKey: "AIzaSyBN0jKVIBgCFL8mvOH6aw7uLF4taTH2X-E",
  authDomain: "samruddhaodisha-032023.firebaseapp.com",
  databaseURL: "https://samruddhaodisha-032023-default-rtdb.firebaseio.com",
  projectId: "samruddhaodisha-032023",
  storageBucket: "samruddhaodisha-032023.appspot.com",
  messagingSenderId: "250813772825",
  appId: "1:250813772825:web:81acddb8f1102defd5e688",
  measurementId: "G-907NRBYKQP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

 export const auth = firebase.auth();
 export const db = firebase.firestore();
 export const storage = firebase.storage();
 export const database = firebase.database();


 export default firebase;