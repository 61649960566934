import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { db } from '../Pages/firebase-config';
import { getDocs, collection } from 'firebase/firestore';
import member from '../images/member.jpeg'

const CounterAnimation = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchTotal = async () => {
      const querySnapshot = await getDocs(collection(db, 'Members'));
      const memberSize = 951323+querySnapshot.size;
      setTotal(memberSize);
    };
    fetchTotal();
  }, []);

  return (
    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
      <div
        style={{
          width: '100%',
          background: 'linear-gradient(#FF671F, #FFFFFF, #046A38)',
          color: 'black',
          padding: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1>Happy Members</h1>
        </div>
        <div style={{display:'flex'}}>
        <img src={member} alt="Member Icon" style={{ marginRight: '10px',height:'30px',width:'25px' }} />
        <h1>
          {counterOn && (
            <CountUp start={900000} end={total} duration={2} delay={0} />
          )}
          +
        </h1>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default CounterAnimation;