import React, {useState, useEffect} from 'react';
import Typewriter from 'typewriter-effect';
import './Designs/ChangiText.css' // You can define your CSS styles

const newsTexts = [
  'NEXT ODISHA PROVERTY FREE ODISHA',
  'NEXT ODISHA CORUPTION FREE ODISHA',
];

function ChangingTextComponent() {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % newsTexts.length);
    }, 1000); // Switch text every 3 seconds (3000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  return (

    <div className="news-ticker">
      <div className="ticker-text">
        {newsTexts[currentTextIndex]}
      </div>
    </div>
  );

}

export default ChangingTextComponent;


