
export const cons = ['ANGUL',
'ATHAMALLIK',
'CHHENDIPADA',
'PALLAHARA',
'TALCHER',
'BOLANGIR',
'KANTABANJI',
'LOISINGHA',
'PATNAGARH',
'TITLAGARH',
'BALASORE',
'BASTA',
'BHOGRAI',
'JALESWAR',
'NILGIRI',
'REMUNA',
'SIMULIA',
'SORO',
'ATTABIRA',
'BARGARH',
'BHATLI',
'BIJEPUR',
'PADAMPUR',
'BASUDEVPUR',
'BHADRAK',
'BHANDARIPOKHARI',
'CHANDABALI',
'DHAMNAGAR',
'BOUDH',
'KANTAMAL',
'ATHAGARH',
'BANKI',
'BARABATI-CUTTACK',
'BARAMBA',
'CHOUDWAR-CUTTACK',
'CUTTACK SADAR',
'MAHANGA',
'NIALI',
'SALIPUR',
'DEOGARH',
'DHENKANAL',
'HINDOL',
'KAMAKHYANAGAR',
'PARJANGA',
'MOHANA',
'PARALAKHEMUNDI',
'ASKA',
'BERHAMPUR',
'BHANJANAGAR',
'CHHATRAPUR',
'CHIKITI',
'DIGAPAHANDI',
'GOPALPUR',
'HINJILI',
'KABISURYANAGAR',
'KHALIKOTE',
'POLASARA',
'SANAKHEMUNDI',
'SURADA',
'BALIKUDA-ERASAMA',
'JAGATSINGHPUR',
'PARADEEP',
'TIRTOL',
'BARACHANA',
'BARI',
'BINJHARPUR',
'DHARMASALA',
'JAJPUR',
'KOREI',
'SUKINDA',
'BRAJARAJNAGAR',
'JHARSUGUDA',
'BHAWANIPATNA',
'DHARAMGARH',
'JUNAGARH',
'LANJIGARH',
'NARLA',
'BALIGUDA',
'G. UDAYAGIR',
'PHULBANI',
'AUL',
'KENDRAPARA',
'MAHAKALAPADA',
'PATKURA',
'RAJANAGAR',
'ANANDAPUR',
'CHAMPUA',
'GHASIPURA',
'KEONJHAR',
'PATNA',
'TELKOI',
'BEGUNIA',
'BHUBANESWAR CENTRAL',
'BHUBANESWAR NORTH',
'CHILIKA',
'EKAMRA-BHUBANESWAR',
'JATANI',
'JAYADEV',
'KHURDA',
'JEYPORE',
'KORAPUT',
'KOTPAD',
'LAKSHMIPUR',
'POTTANGI',
'CHITRAKONDA',
'MALKANGIRI',
'BADASAHI',
'BANGRIPOSI',
'BARIPADA',
'JASHIPUR',
'KARANJIA',
'MORADA',
'RAIRANGPUR',
'SARASKANA',
'UDALA',
'DABUGAM',
'JHARIGAM',
'NABARANGPUR',
'UMERKOTE',
'DASPALLA',
'KHANDAPADA',
'NAYAGARH',
'RANPUR',
'KHARIAR',
'NUAPADA',
'BRAHMAGIRI',
'KAKATPUR',
'NIMAPARA',
'PIPILI',
'PURI',
'SATYABADI',
'BISSAM CUTTACK',
'GUNUPUR',
'RAYAGADA',
'KUCHINDA',
'RAIRAKHOL',
'RENGALI',
'SAMBALPUR',
'BIRMAHARAJPUR',
'SONEPUR',
'BIRAMITRAPUR',
'BONAI',
'RAGHUNATHPALI',
'RAJGANGPUR',
'ROURKELA',
'SUNDARGARH',
'TALSARA'

];