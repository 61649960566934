import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import ExcelJS from 'exceljs';
import './Designs/tablestyle.css';
import Loading from '../../components/Loading';
import { cons } from '../../Data/const';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const VolunteerTable = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedGP, setSelectedGP] = useState('');
    const [selectBlock, setSelectedBlock] = useState('');
    const [selectedCons, setSelectedCons] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const userCollection = db.collection('TaskUpdate');
                const snapshot = await userCollection.get();
                const usersData = [];


                let index = 1;

                snapshot.forEach((doc) => {
                    const {Date,Taskid, Tasktitle,username, userphone, status, additionalDetails,Block} = doc.data();
                    usersData.push({
                        Date,
                        Taskid,
                        additionalDetails,
                        username,
                        userphone,
                        status,
                        Tasktitle,
                        Block
                    });
                });

                setUserData(usersData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    const handleDownloadExcel = async () => {

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('UserData');

        worksheet.addRow([
            'Date Of Join',
            'Member ID',
            'Member Name',
            'Father Name',
            'Mobile No.',
            'Member Category',
            'Village',
            'Gram Panchayat',
            'Block',
            'Constituency',
            'District',
            'PIN Code',
        ]);

        // Add filtered data rows
        userData.forEach((user) => {
            worksheet.addRow([
                user.Date,
                user.MemberId,
                user.Name,
                user.Father,
                user.MobileNo,
                user.MemberCategory,
                user.Village,
                user.GramPanchayat,
                user.Block,
                user.Constituency,
                user.Dist,
                user.PIN,
            ]);
        });

        // Create a Blob with the filtered workbook data
        const blob = await workbook.xlsx.writeBuffer();

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'MemberData.xlsx';
        a.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(url);
    };


    if (loading) {
        return <div>
            <Loading />
        </div>;
    }

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date </th>
                        <th>Task ID</th>
                        <th>Name</th>
                        <th>Mobile No.</th>
                        {/* <th>Gram Panchayat</th> */}
                        <th>Block</th>
                        {/* <th>Constituency</th> */}
                        {/* <th>District</th> */}
                        <th>Task Details</th>
                        <th>Status</th>

                    </tr>
                </thead>

                <tbody>
                    {userData.map((user) => (
                        <tr key={user.userphone}>
                            <td>{user.Date}</td>
                            <td>{user.Taskid}</td>
                            <td>{user.username}</td>
                            <td>{user.userphone}</td>
                            <td>{user.Block}</td>
                            <td>{user.additionalDetails}</td>
                            <td>{user.status}</td>
                            {/* <td>{user.GramPanchayat}</td> */}
                            {/* <td>{user.Constituency}</td> */}
                            {/* <td>{user.Dist}</td> */}

                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="centered">
                <button onClick={handleDownloadExcel}>
                    <FontAwesomeIcon icon={faDownload} /> Download Excel
                </button>
            </div>
        </div>
    );
};

export default VolunteerTable;
