import * as Yup from "yup";

export const signUpSchema = Yup.object({
  name: Yup.string().required("Please enter your Name"),
  date: Yup.date().required("Please enter Date"),
  fathersname: Yup.string().required("Please enter your Father's Name"),
  dist: Yup.string().required("Please enter District"),
  block: Yup.string().required("Please enter Block"),
  cons: Yup.string().required("Please enter Consituency"),
  gp: Yup.string().required("Please enter GramPanchayat"),
  village: Yup.string().required("Please enter Village"),
  // mobilenumber: Yup.number()
  // .required("Input is required")
  // .length(10, "Input must be exactly 10 characters long"),
  pin: Yup.number().min(6).max(6).required("Please enter PIN"),
  mc: Yup.string().required("Please enter MemberCategory"),

});