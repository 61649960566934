import './App.css'
import Home from './Pages/Home';
import { Switch, Route, Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Newmember from './Pages/Newmember';
import Donation from './Pages/Donation';
import NavBar from './components/Navbar';
import i18n from "./components/i18n";
import { useState, useEffect } from 'react';
import Show from './Pages/Volunteer/ShowUser.js';
import Taskadmin from './Pages/Admin/Taskadmin';
import Login from './Pages/Volunteer/Login';
import ShowAdmin from './Pages/Admin/ShowAdmin';
import TaskUpdate from './Pages/Admin/UpdatedTask';
import { useAuth } from './Context/AuthProvider.js';
import AdminLogin from './Pages/Admin/adminLogin';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import TableComponent from './Pages/Admin/MemberTable';
import VolunteerTable from './Pages/Admin/VolunteerTable.js';

function App() {
  const [language, setLanguage] = useState("en");
  const { currentUser } = useAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useHistory

  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "es" : "en";
    i18n.changeLanguage(newLanguage).then(() => {
      setLanguage(newLanguage);
    });
    console.log("click");
  };


  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      // Use the stored authentication token to re-authenticate the user
      firebase
        .auth()
        .signInWithCustomToken(userToken)
        .then(() => {
          // User is successfully re-authenticated
          // Navigate to your authenticated dashboard or main page
          history.push('/Volunter');
        })
        .catch((error) => {
          // Handle any errors that occur during re-authentication
          console.error('Auto-login error:', error);
        });
    }
  }, []);

  const checkLogin = (username, password) => {
    if (username === 'SamruddhaOdisha' && password === '123456') {
      setLoggedIn(true);
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };



  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Redirect to="/login" />;
    }
    return children;
  };


  return (

    <>

      {/* Conditionally render the NavBar */}

      {window.location.pathname !== '/admin' &&
        window.location.pathname !== '/TaskManagement' &&
        window.location.pathname !== '/UpdatedTask' &&
        window.location.pathname !== '/newtask' &&
        (<NavBar toggleLanguage={toggleLanguage} lang={language} />)
      }



      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Newmember" component={Newmember} />
        <Route exact path="/Donation" component={Donation} />
        <Route exact path="/login" component={Login} />


        <Route exact path="/TaskManagement">
          {loggedIn ? (
            <div><ShowAdmin /></div>
          ) : (
            <Redirect to="/admin" />
          )}        </Route>



        <Route exact path="/UpdatedTask">
          {loggedIn ? (
            <div><TaskUpdate /></div>
          ) : (
            <Redirect to="/admin" />)}
        </Route>

        <Route exact path="/newtask">
          {loggedIn ? (
            <div><Taskadmin /></div>
          ) : (
            <Redirect to="/admin" />)}
        </Route>

        <Route exact path="/memberdata">
          {loggedIn ? (
            <div><TableComponent /></div>
          ) : (
            <Redirect to="/admin" />)}
        </Route>

        <Route exact path="/volunterdata">
          {loggedIn ? (
            <div><VolunteerTable /></div>
          ) : (
            <Redirect to="/admin" />)}
        </Route>

        <Route exact path="/admin">
          <AdminLogin setLoggedIn={checkLogin} /> {/* Pass the setLoggedIn function */}
        </Route>


        <Route exact path="/Volunter">
          <ProtectedRoute>
            <Show />
          </ProtectedRoute>
        </Route>
        <Redirect to="/" />
      </Switch>

    </>
  );
}

export default App;