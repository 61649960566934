import React, { useEffect } from "react";
import styled from "styled-components";
import { GlobalStyle } from "./Designs/Styles/globalStyles";
import { useFormik } from "formik";
import { signUpSchema } from "./Designs/schemas/index1";
import slogo1 from "../images/donation.jpeg";
import { useState } from "react";
import { db } from "./firebase-config.js";
import { doc, setDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";


const Donation = () => {
  useEffect(() => {
    document.title = "SamruddhaOdisha | Donation";
  }, []);

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    amount: "",
    date: "",
  });

  const { errors, touched, handleBlur } =
    useFormik({
      formData,
      validationSchema: signUpSchema,
    });



  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const [errorMessage, setErrorMessage] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.name === "" ||
      formData.address === "" ||
      formData.amount === "" ||
      formData.date === ""
    ) {
      setErrorMessage("Please fill in all fields");
      return;
    }

    // const userCollectionsRef = collection(db, "DonationMembers");
    const createUser = async () => {
      await setDoc(doc(db, "DonationMembers", formData.name), {
        Name: formData.name,
        Address: formData.address,
        ID: formData.name + "002",
        Amount: formData.amount,
        Date: formData.date,
      });
    };
    createUser();
    console.log(formData);

    alert("Thank you for contribution");

    setFormData({
      name: "",
      address: "",
      amount: "",
      date: "",
    });

  };

  // Get Users current Location
  const LocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        // Fetch address from OpenCage Geocoding API
        fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=292df61d528448ce80e158db33a08990`
        )
          .then((response) => response.json())
          .then((data) => {
            const address = data.results[0].formatted;
            setFormData({ ...formData, address });
          })
          .catch((error) => {
            console.log("Error fetching address:", error);
          });
      });
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Samruddha Odisha | Donation</title>
        <meta
          name='description'
          content='Give a donation'
        ></meta>
        <link rel="canonical" href="/donation"></link>
      </Helmet>

      <GlobalStyle />
      <Wrapper>
        <div className="container">
          <div className="modal">
            <div className="modal-container">
              <div className="modal-left">
                <h1 className="modal-title">Welcome!</h1>
                <p className="modal-desc">
                  To Samruddha Odisha Political party
                </p>
                <form onSubmit={formSubmit}>
                  <div className="input-block">
                    <label htmlFor="name" className="input-label">
                      {t("donation.donation.name")}
                    </label>
                    <input
                      type="name"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.name === '' && touched.name ? (
                      <p className="form-error">{errors.name}</p>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label htmlFor="amount" className="input-label">
                      {t("donation.donation.amount")}
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="amount"
                      id="amount"
                      placeholder="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.amount === '' && touched.amount ? (
                      <p className="form-error">{errors.amount}</p>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label htmlFor="date" className="input-label">
                      {t("donation.donation.date")}
                    </label>
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date"
                      value={formData.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.date === '' && touched.date ? (
                      <p className="form-error">{errors.date}</p>
                    ) : null}
                  </div>



                  <div className="input-block">
                    <label htmlFor="address" className="input-label">
                      {t("donation.donation.address")}
                    </label>
                    <input
                      type="string"
                      autoComplete="off"
                      name="address"
                      id="address"
                      placeholder="address"
                      value={formData.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.address === '' && touched.address ? (
                      <p className="form-error">{errors.address}</p>
                    ) : null}
                  </div>

                  <div className="locationboarder">
                    <div className="locationicon" type="button" onClick={LocationClick}>
                      <div className="l1">

                        <div>
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </div>
                        Get Location
                      </div>
                    </div>
                  </div>

                  <div className="modal-buttons">

                    <button className="input-button" type="submit">
                      Donate
                    </button>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                  </div>
                </form>
              </div>
              <div className="modal-right">
                <img src={slogo1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #efedee;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    width: 100%;
    /* height: 60px; */
    background: rgba(51, 51, 51, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
  }
  .modal-container {
    display: flex;
    max-width: 60vw;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    transition-duration: 0.3s;
    background: #fff;
  }
  .modal-title {
    margin: 0;
    font-weight: 400;
    color: #99292D;
  }
  .form-error {
    font-size: 1.4rem;
    color: #b22b27;
  }
  .modal-desc {
    margin: 6px 0 30px 0;
  }
  .modal-left {
    padding: 60px 30px 20px;
    background: #fff;
    flex: 1.5;
    transition-duration: 0.5s;
    opacity: 1;
  }
  .modal-right {
    flex: 1;
    height: 500px;
    width: 500px;
    font-size: 0;
    transition: 0.3s;
    overflow: hidden;
  }
  .modal-right img {
    width: 80%;
    height: 100%;
    transform: scale(1);
    -o-object-fit: cover;
    object-fit: cover;
    transition-duration: 1.2s;
  }
  .modal.is-open .modal-left {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.1s;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .modal-buttons a {
    color: rgba(51, 51, 51, 0.6);
    font-size: 14px;
  }
  .sign-up {
    margin: 60px 0 0;
    font-size: 14px;
    text-align: center;
  }
  .sign-up a {
    color: #8c7569;
  }
  .input-button {
    padding: 1.2rem 3.2rem;
    outline: none;
    text-transform: uppercase;
    border: 0;
    color: #fff;
    border-radius: 4px;
    background: #0000FF;
    transition: 0.3s;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
  }
  .input-button:hover {
    background: #8B8000;
  }
  .input-label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000080;
    transition: 0.3s;
  }
  .input-block {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: 0.3s;
  }

  .input-block1 {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 8px;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-bottom: 5px;
    transition: 0.3s;
  }

  .chose {
    font-size: 15px;
    padding: 5px 5px 8px;
    margin-left: 0px;
  }
  .input-block input {
    outline: 0;
    border: 0;
    padding: 4px 0 0;
    font-size: 14px;
  }
  .input-block input::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block input:-ms-input-placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block input::placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block:focus-within {
    border-color: #8c7569;
  }
  .input-block:focus-within .input-label {
    color: rgba(140, 117, 105, 0.8);
  }
  .locationboarder{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0px;
    align-items: center;
    
  }

  .locationbutton{
    padding: 1.2rem 3.2rem;
    outline: none;
    text-transform: uppercase;
    border: 0;
    color: white;
    border-radius: 4px;
    background: #0000ff;
    transition: 0.3s;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
  }
  .locationicon {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: 0.3s;
    background: #0000ff;
    color:rgb(255, 255, 255);
  }

  .l1 {
    display: flex;
    flex-direction: row;
    // letter-spacing: 0.900em;
  }
  @media (max-width: 750px) {
    .modal-container {
      max-width: 90vw;
    }
    .modal-right {
      display: none;
    }
  }
`;

export default Donation;
