import React, { useEffect } from "react";
import styled from "styled-components";
import { GlobalStyle } from "./Designs/Styles/globalStyles";
import { useFormik } from "formik";
import { signUpSchema } from "./Designs/schemas/index";
import { useState } from "react";
import { Dist } from "../Data/district";
import { db } from "./firebase-config.js";
import { doc, setDoc, getDocs, collection, query } from "firebase/firestore";
import { GP } from "../Data/gp";
import { cons } from "../Data/const";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Loading from "../components/Loading";
import Select from 'react-select';

const NewMember = () => {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const [formData, setFormData] = useState({
    date: "",
    name: "",
    fathersname: "",
    mobilenumber: "",
    district: "",
    block: "",
    concituency: "",
    gp: "",
    village: "",
    pin: "",
    MemberCategory: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setFormData({ ...formData, district: selectedDistrict, block: '' });
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [moberrorMessage, mobsetErrorMessage] = useState("");
  const [pinErrorMessage, setPinErrorMessage] = useState("");


  const isIndianPhoneNumber = (value) => {
    // Regular expression to validate Indian phone number
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(value);
  };

  const isIndianPINCode = (value) => {
    // Regular expression to validate Indian PIN code format
    const pinRegex = /^[1-9][0-9]{5}$/;
    return pinRegex.test(value);
  };



  // OnSubmit
  const formSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.date === "" ||
      formData.name === "" ||
      formData.fathersname === "" ||
      formData.mobilenumber === "" ||
      formData.district === "" ||
      formData.block === "" ||
      formData.village === "" ||
      formData.gp === "" ||
      formData.concituency === "" ||
      formData.MemberCategory === "" ||
      formData.pin === ""
    ) {
      setErrorMessage("Please fill in all fields");
      return;
    }

    if (!isIndianPhoneNumber(formData.mobilenumber)) {
      mobsetErrorMessage("Please enter a valid Indian mobile number");
      return;
    }

    if (!isIndianPINCode(formData.pin)) {
      setPinErrorMessage("Please enter a valid Indian PIN code");
      return;
    }
    setIsLoading(true);

    const createUser = async () => {

      const querySnapshot = await getDocs(query(collection(db, "Members")));
      const empCount = querySnapshot.size;

      const prefix = "SO";
      const pNO = String(empCount + 1).padStart(9, "0");
      const empId = prefix + pNO;

      await setDoc(doc(db, "Members", empId), {
        Date: formData.date,
        Name: formData.name,
        Block: formData.block,
        MemberId: empId,
        Father: formData.fathersname,
        Date: formData.date,
        Constituency: formData.concituency,
        Dist: formData.district,
        GramPanchayat: formData.gp,
        MobileNo: formData.mobilenumber,
        MemberCategory: formData.MemberCategory,
        village: formData.village,
        PIN: formData.pin
      });
    };
    createUser();
    alert("Member Registered");
    setFormData({
      date: "",
      name: "",
      fathersname: "",
      mobilenumber: "",
      district: "",
      block: "",
      concituency: "",
      gp: "",
      village: "",
      MemberCategory: "",
      pin: ""
    });
    setIsLoading(false);
  };

  const { values, errors, touched, handleBlur } =
    useFormik({
      formData,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        action.resetForm();
      },
    });

  const Consoptions = cons.map((option) => ({ value: option, label: option }));
  const Gpoptions = GP.map((option) => ({ value: option, label: option }));

  return (
    <>
      <Helmet>
        <title>Samruddha Odisha | NewMember</title>
        <meta
          name='description'
          content='Join as a member of Samruddha Odisha'
        ></meta>
        <link rel="canonical" href="/newmember"></link>
      </Helmet>

      <GlobalStyle />
      <Wrapper>
        <div className="container">
          <div className="modal">
            <div className="modal-container">
              <div className="modal-left">
                <h1 className="modal-title">Welcome!</h1>
                <p className="modal-desc">
                  To Samruddha Odisha Political party
                </p>
                <form onSubmit={formSubmit}>
                  <div className="input-block">
                    <label htmlFor="date" className="input-label">
                      {t("member.member.date")}
                    </label>
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date"
                      value={formData.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.date === '' && touched.date ? (
                      <p className="form-error">{errors.date}</p>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label htmlFor="name" className="input-label">
                      {/* Name */}
                      {t("member.member.name")}
                    </label>
                    <input
                      type="name"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.name === '' && touched.name ? (
                      <p className="form-error">{errors.name}</p>
                    ) : null}
                  </div>

                  {/* fathersname */}
                  <div className="input-block">
                    <label htmlFor="fathersname" className="input-label">
                      {/* Fathers's Name */}
                      {t("member.member.fathersname")}
                    </label>
                    <input
                      type="name"
                      autoComplete="off"
                      name="fathersname"
                      id="fathersname"
                      placeholder="Father's name"
                      value={formData.fathersname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.fathersname === '' && touched.fathersname ? (
                      <p className="form-error">{errors.fathersname}</p>
                    ) : null}
                  </div>

                  {/* mobilenumber */}
                  <div className="input-block">
                    <label htmlFor="mobilenumber" className="input-label">
                      {/* Mobile Number */}
                      {t("member.member.mobile")}
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="mobilenumber"
                      id="mobilenumber"
                      placeholder="mobile number"
                      value={formData.mobilenumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.mobilenumber === '' && touched.mobilenumber ? (
                      <p className="form-error">{moberrorMessage}</p>
                    ) : null}

                    {<p className="form-error">{moberrorMessage}</p>}

                  </div>

                  {/* Dist */}
                  <div className="input-block1">
                    <label htmlFor="colors" className="input-label1">
                      {/* Please select your district */}
                      {t("member.member.dist")}
                    </label>
                    <select
                      className="chose"
                      name="district"
                      value={formData.district}
                      onChange={handleDistrictChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select a district</option>
                      {Dist.map((district) => (
                        <option key={district.name} value={district.name}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                    {formData.district === '' && touched.district ? (
                      <p className="form-error">{errors.dist}</p>
                    ) : null}
                  </div>

                  {/* block */}
                  <div className="input-block1">
                    <label htmlFor="colors" className="input-label1">
                      {/* Please select your Block */}
                      {t("member.member.block")}
                    </label>

                    <select
                      className="chose"
                      name="block"
                      value={formData.block}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select a block</option>
                      {Dist.find((district) => district.name === formData.district)?.blocks.map((block) => (
                        <option key={block} value={block}>
                          {block}
                        </option>
                      ))}
                    </select>

                    {formData.block === '' && touched.block ? (
                      <p className="form-error">{errors.block}</p>
                    ) : null}
                  </div>

                  {/* Constituency */}
                  <div className="input-block1">
                    <label htmlFor="colors" className="input-label1">
                      {/* Please select your Concituency */}
                      {t("member.member.cons")}
                    </label>
                    <Select
                      className="chose"
                      name="constituency"
                      value={Consoptions.find((option) => option.value === formData.concituency)}
                      onChange={(selectedOption) => setFormData({ ...formData, constituency: selectedOption.value })}
                      options={Consoptions}
                      placeholder="Select a Constituency"
                    />

                    {formData.concituency === '' && touched.concituency ? (
                      <p className="form-error">{errors.cons}</p>
                    ) : null}
                  </div>

                  {/* grampanchayat  */}
                  <div className="input-block1">
                    <label htmlFor="colors" className="input-label1">
                      {/* Please select your Gram Panchayat */}
                      {t("member.member.gp")}
                    </label>
                    <Select
                      className="chose"
                      name="gp"
                      value={Gpoptions.find((option) => option.value === formData.gp)}
                      onChange={(selectedOption) => setFormData({ ...formData, gp: selectedOption.value })}
                      options={Gpoptions}
                      placeholder="Select a Gram Panchayat"
                    />

                    {formData.gp === '' && touched.gp ? (
                      <p className="form-error">{errors.gp}</p>
                    ) : null}
                  </div>

                  {/* village */}
                  <div className="input-block">
                    <label htmlFor="village" className="input-label">
                      {t("member.member.village")}
                    </label>
                    <input
                      type="string"
                      autoComplete="off"
                      name="village"
                      id="village"
                      placeholder="village"
                      value={formData.village}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.village === '' && touched.village ? (
                      <p className="form-error">{errors.village}</p>
                    ) : null}
                  </div>

                  {/* pin */}
                  <div className="input-block">
                    <label htmlFor="PIN" className="input-label">
                      {/* PIN */}
                      {t("member.member.pin")}
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="pin"
                      id="pin"
                      placeholder="pin"
                      value={formData.pin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.pin === '' && touched.pin ? (
                      <p className="form-error">{errors.pin}</p>
                    ) : null}

                    {<p className="form-error">{pinErrorMessage}</p>}

                  </div>

                  <div className="input-block1">
                    <label htmlFor="membercategory" className="input-label1">
                      {t("member.member.membercategory")}
                    </label>

                    <select
                      className="chose"
                      name="MemberCategory"
                      value={formData.MemberCategory}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" label="Select MemberCategory">
                        Select MemberCategory{" "}
                      </option>
                      <option value="General" label="General">
                        {" "}
                        General
                      </option>
                      <option value="Block Executive" label="Block Executive">
                        Block Executive
                      </option>

                      <option value="District Executive" label="District Executive">
                        District Executive
                      </option>

                      <option value="State Executive" label="State Executive">
                        State Executive
                      </option>
                    </select>

                    {formData.MemberCategory === '' && touched.MemberCategory ? (
                      <p className="form-error">{errors.mc}</p>
                    ) : null}
                  </div>



                  <div className="modal-buttons">
                    <button className="input-button" type="submit">
                      Registration
                    </button>
                    {isLoading && <Loading />}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #efedee;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
  }
  .modal-container {
    display: flex;
    max-width: 120vw;
    width: 100%;
    height: 86%;
    border-radius: 10px;
    overflow: scroll;
    position: absolute;
    transition-duration: 0.3s;
    background: #fff;
  }
  .modal-title {
    margin: 0;
    font-weight: 400;
    color: #99292D;
  }
  .form-error {
    font-size: 1.4rem;
    color: #b22b27;
  }
  .modal-desc {
    margin: 6px 0 30px 0;
  }
  .modal-left {
    padding: 60px 30px 20px;
    background: #fff;
    flex: 1.5;
    transition-duration: 0.5s;
    opacity: 1;
  }
  .modal-right {
    flex: 1;
    height: 500px;
    width: 500px;
    font-size: 0;
    transition: 0.3s;
    overflow: hidden;
  }
  .modal-right img {
    width: 80%;
    height: 100%;
    transform: scale(1);
    -o-object-fit: cover;
    object-fit: cover;
    transition-duration: 1.2s;
  }
  .modal.is-open .modal-left {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.1s;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .modal-buttons a {
    color: rgba(51, 51, 51, 0.6);
    font-size: 14px;
  }
  .sign-up {
    margin: 60px 0 0;
    font-size: 14px;
    text-align: center;
  }
  .sign-up a {
    color: #8c7569;
  }
  .input-button {
    padding: 1.2rem 3.2rem;
    outline: none;
    text-transform: uppercase;
    border: 0;
    color: #fff;
    border-radius: 4px;
    background: #0000FF;
    transition: 0.3s;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
  }
  .input-button:hover {
    background: #8B8000;
  }
  .input-label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000080;
    transition: 0.3s;
  }

  .input-label1{
    color: #000080;
    text-size:50px;
  }

  .chose{
    color: #8B8000;
  }
  .input-block {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: 0.3s;
  }

  .input-block1 {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 8px;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-bottom: 5px;
    transition: 0.3s;
    text-style:bold;
    text-size:50px;
  }

  .chose {
    font-size: 15px;
    padding: 5px 5px 8px;
    margin-left: 0px;
  }
  .input-block input {
    outline: 0;
    border: 0;
    padding: 4px 0 0;
    font-size: 14px;
  }
  .input-block input::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block input:-ms-input-placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block input::placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block:focus-within {
    border-color: #8c7569;
  }
  .input-block:focus-within .input-label {
    color: rgba(140, 117, 105, 0.8);
  }
  @media (max-width: 750px) {
    .modal-container {
      max-width: 90vw;
    }
    .modal-right {
      display: none;
    }
  }
`;

export default NewMember;
