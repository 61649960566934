import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { db } from "../firebase-config.js";
import { doc, setDoc } from "firebase/firestore";
import { Dist } from "../../Data/district.js";
import { GP } from "../../Data/gp.js";
import { cons } from "../../Data/const.js";
import Select from 'react-select';

const Taskadmin = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    District: "",
    GP: [],
    Cons: [],
    Block: "",
    Level: "",
  });


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleGPChange = (selectedOptions) => {
    const selectedGP = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, GP: selectedGP });
  };

  const handleConsChange = (selectedOptions) => {
    const selectedCons = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, Cons: selectedCons });
  };

  const gpOptions = GP.map((option) => ({ value: option, label: option }));
  const consOptions = cons.map((option) => ({ value: option, label: option }));

  const [errorMessage, setErrorMessage] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.title === "" ||
      formData.description === "" ||
      formData.date === "" ||
      formData.Level === "" ||
      formData.District === "" ||
      formData.Block === "" ||
      formData.GP.length === 0 ||
      formData.Cons.length === 0
    ) {
      setErrorMessage("Please fill in all fields");
      return;
    } else {
      setErrorMessage("");
    }
    const createUser = async () => {
      const num = Math.floor(Math.random() * 100 + 1);
      const gpString = formData.GP.join(', ');
      const consString = formData.Cons.join(', ');
      await setDoc(doc(db, "VolunteerTask", "Task" + num), {
        Title: formData.title,
        Description: formData.description,
        Date: formData.date,
        District: formData.District,
        GP: gpString,
        Cons: consString,
        Block: formData.Block,
        Level: formData.Level,
        id: num
      });
    };
    createUser();

    setFormData({
      title: "",
      description: "",
      date: "",
      District: "",
      GP: "",
      Cons: "",
      Block: "",
      Level: "",
    });
  };

  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="modal">
            <div className="modal-container">
              <div className="modal-left">
                <h1 className="modal-title">Samruddha Odisha</h1>
                <p className="modal-desc">Task Management System</p>
                <div className="form_t"></div>
                <form onSubmit={formSubmit}>
                  <div className="input-block_title">
                    <label htmlFor="title" className="input-label">
                      Task title
                    </label>
                    <input
                      type="name"
                      autoComplete="off"
                      name="title"
                      id="title"
                      placeholder="title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                    {formData.title === '' ? (
                      <p className="form-error">Enter a Title</p>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label htmlFor="description" className="input-label">
                      Task description
                    </label>
                    <textarea
                      type="string"
                      aria-multiline={true}
                      autoComplete="off"
                      id="description"
                      name="description"
                      placeholder="description"
                      value={formData.description}
                      onChange={handleChange}
                    />
                    {formData.description === '' ? (
                      <p className="form-error">Enter Description</p>
                    ) : null}
                  </div>

                  <div className="dbg">
                    <div className="input-block1">
                      <label htmlFor="District" className="input-label1">
                        District
                      </label>

                      <select
                        className="chose"
                        name="District"
                        value={formData.District}
                        onChange={handleChange}
                      >
                        <option value="">Select a district</option>
                        {Dist.map((district) => (
                          <option key={district.name} value={district.name}>
                            {district.name}
                          </option>
                        ))}
                      </select>

                      {formData.District === '' ? (
                        <p className="form-error">Select a District</p>
                      ) : null}
                    </div>

                    {formData.District && (
                      <div className="input-block1">
                        <label htmlFor="Block" className="input-label1">
                          Block
                        </label>

                        <select
                          className="chose"
                          name="Block"
                          value={formData.Block}
                          onChange={handleChange}
                        >
                          <option value="">Select a block</option>
                          {Dist.find((district) => district.name === formData.District)?.blocks.map((block) => (
                            <option key={block} value={block}>
                              {block}
                            </option>
                          ))}
                        </select>

                        {formData.Block === '' ? (
                          <p className="form-error">Select a Block</p>
                        ) : null}
                      </div>
                    )}

                    <div className="input-block1">
                      <label className="input-label1">Grampanchayat</label>
                      <Select
                        isMulti
                        name="GP"
                        options={[
                          { value: 'All', label: 'All' },
                          ...gpOptions,
                        ]}
                        value={formData.GP.includes('All') ? [{ value: 'All', label: 'All' }] : gpOptions.filter((option) => formData.GP.includes(option.value))}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.some((option) => option.value === 'All')) {
                            setFormData({ ...formData, GP: ['All'] });
                          } else {
                            setFormData({ ...formData, GP: selectedOptions.map((option) => option.value) });
                          }
                        }}
                      />
                    </div>

                    <div className="input-block1">
                      <label className="input-label1">Constituency</label>
                      <Select
                        isMulti
                        name="Cons"
                        options={[
                          { value: 'All', label: 'All' },
                          ...consOptions,
                        ]}
                        value={formData.Cons.includes('All') ? [{ value: 'All', label: 'All' }] : consOptions.filter((option) => formData.Cons.includes(option.value))}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.some((option) => option.value === 'All')) {
                            setFormData({ ...formData, Cons: ['All'] });
                          } else {
                            setFormData({ ...formData, Cons: selectedOptions.map((option) => option.value) });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="dm">
                    <div className="input-block_date">
                      <label htmlFor="date" className="input-label">
                        Date
                      </label>
                      <input
                        type="date"
                        autoComplete="off"
                        name="date"
                        id="date"
                        placeholder="Date"
                        value={formData.date}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-block_level">
                      <label htmlFor="Level" className="input-label1">
                        Level
                      </label>

                      <select
                        className="chose"
                        name="Level"
                        value={formData.Level}
                        onChange={handleChange}
                      >
                        <option value="" label="Select MemberCategory">
                          Select Level{" "}
                        </option>
                        <option
                          value="Grampanchayat Executive"
                          label="Grampanchayat Executive"
                        >
                          {" "}
                          Grampanchayat Executive
                        </option>
                        <option value="Block Executive" label="Block Executive">
                          Block Executive
                        </option>

                        <option value="District Executive" label="District Executive">
                          District Executive
                        </option>

                        <option value="State Executive" label="State Executive">
                          State Executive
                        </option>
                      </select>

                      {formData.Level === "" ? <p>{errorMessage}</p> : null}
                    </div>
                  </div>

                  <div className="modal-buttons">
                    <button className="input-button" type="submit">
                      submit
                    </button>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </Wrapper >
    </>
  );
};

const Wrapper = styled.section`
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    

    background-color: #efedee;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    width: 100%;
    height: 100%; 
    background: rgba(51, 51, 51, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    padding-top: 550px;
    // padding-bottom: 50px;
    // margin-bottom: 100px;
  }
  .modal-container {
    display: flex;
    max-width: 60vw;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    transition-duration: 0.3s;
    background: #fff;
    // margin-bottom: 100px;
  }
  .modal-title {
    margin: 0;
    font-weight: 400;
    color: #99292D;
  }
  .form-error {
    font-size: 1.4rem;
    color: #b22b27;
  }
  .modal-desc {
    margin: 6px 0 30px 0;
  }
  .modal-left {
    padding: 60px 30px 20px;
    background: #fff;
    flex: 1.5;
    transition-duration: 0.5s;
    opacity: 1;
  }
  .modal-right {
    flex: 1;
    height: 500px;
    width: 500px;
    font-size: 0;
    transition: 0.3s;
    overflow: hidden;
  }
  .modal-right img {
    width: 80%;
    height: 100%;
    transform: scale(1);
    -o-object-fit: cover;
    object-fit: cover;
    transition-duration: 1.2s;
  }
  .modal.is-open .modal-left {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.1s;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .modal-buttons a {
    color: rgba(51, 51, 51, 0.6);
    font-size: 14px;
  }
  .sign-up {
    margin: 60px 0 0;
    font-size: 14px;
    text-align: center;
  }
  .sign-up a {
    color: #8c7569;
  }
  .input-button {
    padding: 1.2rem 3.2rem;
    outline: none;
    text-transform: uppercase;
    border: 0;
    color: #fff;
    border-radius: 4px;
    background: #0000FF;
    transition: 0.3s;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
  }
  .input-button:hover {
    background: #8B8000;
  }
  .input-label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000080;
    transition: 0.3s;
  }
  .input-block {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: 0.3s;
  }

  .input-block1 {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 8px;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-bottom: 5px;
    transition: 0.3s;
  }

  .chose {
    font-size: 15px;
    padding: 5px 5px 8px;
    margin-left: 0px;
  }
  .input-block input {
    outline: 0;
    border: 0;
    padding: 4px 0 0;
    font-size: 14px;
  }
  .input-block input::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block input:-ms-input-placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block input::placeholder {
    color: #ccc;
    opacity: 1;
  }
  .input-block:focus-within {
    border-color: #8c7569;
  }
  .input-block:focus-within .input-label {
    color: rgba(140, 117, 105, 0.8);
  }
  .locationboarder{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0px;
    align-items: center;
    
  }

  .locationbutton{
    padding: 1.2rem 3.2rem;
    outline: none;
    text-transform: uppercase;
    border: 0;
    color: white;
    border-radius: 4px;
    background: #0000ff;
    transition: 0.3s;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
  }
  .locationicon {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: 0.3s;
    background: #0000ff;
    color:rgb(255, 255, 255);
  }

  .l1 {
    display: flex;
    flex-direction: row;
    // letter-spacing: 0.900em;
  }
  @media (max-width: 750px) {
    .modal-container {
      max-width: 90vw;
    }
    .modal-right {
      display: none;
    }
  }
`;
export default Taskadmin;



