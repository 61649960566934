
export const Dist = [
    {
        name: 'Anugul',
        blocks:['Angul', 'Banarpal', 'Chhendipada', 'Athmallik', 'Kishorenagar', 'Pallahara', 'Kaniha', 'Talcher'
    ]
    },{
        name: 'Balasore',
        blocks:['Balasore', 'Baliapal', 'Basta', 'Bhograi', 'Jaleswar', 'Khaira', 'Remuna', 'Simulia', 'Soro', 'Nilagiri', 'Oupada', 'Bahanaga'
    ]
    },{
        name: 'Baragarh',
        blocks:['Baragarh', 'Barpalli', 'Attabira', 'Bhatli', 'Bheden', 'Ambabhana', 'Rajborasambar', 'Paikmal', 'Jharabandha', 'Gaisilet', 'Sohela', 'Bijepur'
    ]
    },{
        name: 'Bhadrak',
        blocks:['Basudevpur', 'Bhadrak', 'Bhandaripokhari', 'Bonth', 'Chandabali', 'Dhamanagar', 'Tihidi'
    ]
    },{
        name: 'Bolangir',
        blocks:['Balangir', 'Deogam', 'Gudvella', 'Puintal', 'Luisinga', 'Agalapur', 'Titilagarh', 'Saintala', 'Bangamunda', 'Muribahal', 'Turekela', 'Patnagarh', 'Belapada', 'Khaprakhole'
    ]
    },{
        name: 'Boudh',
        blocks:['Boudh', 'Harbhanga', 'Kantamal'
    ]
    },{
        name: 'Cuttak',
        blocks:['Cuttack sadar', 'Baranga', 'Niali', 'Kantapada', 'Salepur', 'Nischantakoili', 'Mahanga', 'Tangi-Choudwar', 'Athagarh', 'Tigiria', 'Baramba', 'Narasinghpur', 'Banki', 'Dampada'
    ]
    },{
        name: 'Deogarh',
        blocks:['Barkote', 'Reamal', 'Tileibani'
    ]
    },{
        name: 'Dhenkanal',
        blocks:['Dhenkanal(Sadar)', 'Gondia', 'Odapada', 'Hindol', 'Kamakhyanagar', 'Parjang', 'Kankadahad', 'Bhuban'
    ]
    },{
        name: 'Gajapati',
        blocks:['Paralakhemundi', 'Kasinagar', 'Gumma', 'Mohana', 'Nuagada', 'R.Udayagiri', 'Rayagad'
    ]
    },{
        name: 'Ganjam',
        blocks:['Patrapur', 'Chikiti', 'Rangeilunda', 'Digipahandi', 'Sanakhemundi', 'Kukudakhandi', 'Chatrapur', 'Ganjam', 'Purusottampur', 'Hinjilikatu', 'Khallikote', 'Beguniapada', 'Kabisuryanagar', 'Polsara', 'Aska', 'Sheragada', 'Dharakote', 'Sorada', 'Bhanjanagar', 'Belguntha', 'Buguda', 'Jagannath Prasad'
    ]
    },{
        name: 'Jagatsinghpur',
        blocks:['Jagatsinghpur', 'Biridi', 'Naugaon', 'Balikuda', 'Raghunathpur', 'Tirtol', 'Ersama', 'Kujang'
    ]
    },{
        name: 'Jajpur',
        blocks:['Jajpur', 'Dasarathpur', 'Binjharpur', 'Dangadi', 'Sukinda', 'Korei', 'Dharmasala', 'Barchana', 'Rasulpur', 'Bari'
    ]
    },{
        name: 'Jharsuguda',
        blocks:['Jharsuguda', 'Lakhanpur', 'Kirmira', 'Kolabira', 'Laikera'
    ]
    },{
        name: 'Kalahandi',
        blocks:['Bhawanipatna', 'Kesinga', 'M.Rampur', 'Narla', 'Madanpur', 'Thumal Rampur', 'Karlamunda', 'Lanjigarh', 'Dharmagarh', 'Golamunda', 'Jaipatna', 'Junagarh', 'Kalampur', 'Koksara'
    ]
    },{
        name: 'Kendrapara',
        blocks:['Kendrapara', 'Derabish', 'Marsaghai', 'Mahakalapada', 'Garadpur', 'Pattamundai', 'Rajkanika', 'Rajnagar', 'Aul'
    ]
    },{
        name: 'Keonjhar',
        blocks:['Keonjhar', 'Saharapada', 'Telkoi', 'Ghatgaon', 'Bansapal', 'Patna', 'Harichandanpur', 'Anandapur', 'Ghasipura', 'Hatadihi', 'Champua', 'Jhumpura', 'Joda'
    ]
    },{
        name: 'Khordha',
        blocks:['Khurda', 'Begunia', 'Bolagad', 'Bhubaneswar', 'Jatni', 'Balipatna', 'Balianta', 'Tangii', 'Chilika', 'Banapur'
    ]
    },{
        name: 'Koraput',
        blocks:['Koraput', 'Dasamanthpur', 'Similiguda', 'Nandapur', 'Pottangi', 'Lamptaput', 'Narayanapatna', 'Bandhugaon', 'Laxmipur', 'jeypore', 'Kundra', 'Boriguma', 'Kotpad', 'Boipariguda'
    ]
    },{
        name: 'Malkangiri',
        blocks:['Malkanagiri', 'Korukonda', 'Mathili', 'Kallimela', 'Podia', 'Khairaput', 'Kudumulgumma'
    ]
    },{
        name: 'Mayurbhanj',
        blocks:['Baripada', 'Samakhunta', 'Suliapdad', 'Betanati', 'Badasahi', 'Rasagobindapur', 'Bangiriposhi', 'Kuliana', 'Sarasakana', 'Udala', 'Khunta', 'Kaptipada', 'Karanjia', 'Thakurmunda', 'Sukuruli', 'Jashipur', 'Raruan', 'Rairangapur', 'Jamda', 'Bijatala', 'Bisoi', 'Gopabandhunagar', 'Tiring', 'Kusumi', 'Moroda', 'Bahalda'
    ]
    },{
        name: 'Nawarangpur',
        blocks:['Nawarangpur', 'Nandahandi', 'Tentulikhunti', 'Papadahandi', 'Kosagumuda', 'Dabugaon', 'Umerkote', 'Jharigaon', 'Chandahandi', 'Raighar'
    ]
    },{
        name: 'Nayagarh',
        blocks:['Nayagarh', 'Ranpur', 'Odagaon', 'Nuagaon', 'Khandapada', 'Bhapur', 'Daspalla', 'Gania'
    ]
    },{
        name: 'Nuapada',
        blocks:['Nuapada', 'Boden', 'Khariar', 'Komna', 'Sinapalli'
    ]
    },{
        name: 'Kandhamal',
        blocks:['Phulbani', 'Phiringia', 'Khajuripada', 'K.Nuagaon', 'Daringbadi', 'Balliguda', 'Kotgarh', 'Tumudibandh', 'G.Udayagiri', 'Raikia', 'Tikabali', 'Chakapad'
    ]
    },{
        name: 'Puri',
        blocks:['Puri', 'Sadar', 'Brahmagiri', 'Krushnaprasad', 'Satyabadi', 'Kanas', 'Delang', 'Pipili', 'Nimapada', 'Gop', 'Kakatpur', 'Astarang'
    ]
    },{
        name: 'Rayagada',
        blocks:['Rayagada', 'Kolanara', 'Kaalyansinghpur', 'Kaashipur', 'Gunupur', 'Padmapur', 'Ramanaguda', 'Gudari', 'Muniguda', 'Bisam-Cuttack', 'Chandrapur'
    ]
    },{
        name: 'Sambalpur',
        blocks:['Dhankhanda', 'Maneswar', 'Jujumora', 'Rengali', 'Rairakhola', 'Nakatideul', 'Kuchinda', 'Bamara', 'Jamanakira'
    ]
    },{
        name: 'Subarnpur',
        blocks:['Sonepur', 'Tarva', 'Birmaharajpur', 'Ulunda', 'Dunguripalli', 'Binika'
    ]
    },{
        name: 'Sundargarh',
        blocks:['Sundargarh(Sadar)', 'Balisankara', 'Subdega', 'Laphripara', 'Hemgiri', 'Tanngarapalli', 'Rajgangpur', 'Kutra', 'Badagaon', 'Lathikata', 'Nuagaon', 'Kuanrmunda', 'Bisra', 'Gurundia', 'Koira', 'Lahunipada', 'Banaigarh'
    ]
    },
];

