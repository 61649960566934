import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { HelmetProvider } from 'react-helmet-async';
import AuthProvider from './Context/AuthProvider';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <>
    <BrowserRouter>
      <HelmetProvider>
        <AuthProvider>
        <App />
        </AuthProvider>
      </HelmetProvider>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
