import './Designs/Image-gallery.css';
import p1 from '../images/GalleryImgs/Posters-1.png';
import p2 from '../images/GalleryImgs/Posters-2.png';
import p3 from '../images/GalleryImgs/Posters-3.png';
import p4 from '../images/GalleryImgs/Posters-4.png';
import p5 from '../images/GalleryImgs/Posters-5.png';
import p6 from '../images/GalleryImgs/Posters-6.png';
import React, { useEffect, useState } from 'react';
import { Link, Element } from 'react-scroll';
import { useTrail, a } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const posters = [p1, p2, p3, p4, p5, p6];

const CardGallery = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  const [hoveredCard, setHoveredCard] = useState(null);

  const trail = useTrail(posters.length, {
    from: { opacity: 0, transform: 'perspective(600px) rotateY(90deg)' },
    to: { opacity: inView ? 1 : 0, transform: inView ? 'perspective(600px) rotateY(0deg)' : 'perspective(600px) rotateY(90deg)' },
  });

  const handleCardHover = (index) => {
    setHoveredCard(index);
  };

  return (
    <div className="card-gallery" ref={ref}>
      {trail.map((props, index) => (
        <Link
          key={index}
          to={`poster${index + 1}`}
          spy={true}
          smooth={true}
          duration={500}
        >
          <a.div
            className={`card_a ${hoveredCard === index ? 'hovered' : ''}`}
            style={{
              ...props,
              backgroundImage: `url(${posters[index]})`,
            }}
            onMouseEnter={() => handleCardHover(index)}
            onMouseLeave={() => handleCardHover(null)}
          ></a.div>
        </Link>
      ))}

      {trail.map((props, index) => (
        <Element
          key={`poster${index + 1}`}
          name={`poster${index + 1}`}
          className="scroll-element"
        >
        </Element>
      ))}

      {hoveredCard !== null && (
        <div className="fullscreen-overlay">
          <img
            src={posters[hoveredCard]}
            alt={`Poster ${hoveredCard + 1}`}
            className="fullscreen-image"
          />
          
        </div>
      )}
    </div>
  );
};

export default CardGallery;
