import React from "react";
import "./Designs/Footer.css";
import Card from "react-bootstrap/Card";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <>
      <footer className="wholefooter" >
        <div className="footer1">
          <div>
            <Card style={{ width: "15rem" }} className="footer-about">
              <Card.Body>
                <Card.Title id="h3">Samruddha odisha</Card.Title>
                <Card.Subtitle className="mb-2 text-muted" id="h3">
                  Political party
                </Card.Subtitle>
                <Card.Text id="h3">
                  <h3 id="h2">Call Us</h3>
                  <h3 id="h2">+91 8851957752</h3>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="footer-about1">
            <Card style={{ width: "15rem" }} className="footer-about">
              <Card.Body>
                <Card.Title id="h3">Office Hours</Card.Title>
                <Card.Subtitle className="mb-2 text-muted" id="h3">
                  Monday to Saturday
                </Card.Subtitle>
                <Card.Text id="h3">9:00 am to 5:00 pm</Card.Text>
                <Card.Title>Address</Card.Title>
                <Card.Subtitle className="mb-2 text-muted" id="h3">
                  <a href="https://www.google.com/search?sca_esv=554232999&rlz=1C1CHBD_enIN1021IN1021&tbs=lf:1,lf_ui:2&tbm=lcl&q=samruddha+odisha&rflfq=1&num=10&rldimm=4631435349825064970&ved=2ahUKEwjhjf_YhsiAAxXRUGwGHWTnAOwQu9QIegQITRAM#rlfi=hd:;si:4631435349825064970;mv:[[20.4591261,86.1864262],[20.248839099999998,85.769453]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2"
                  target="_blank">
                    B 152, HIG Duplex BDA Colony, Housing Colony, Pragna Vihar
                  </a>
                </Card.Subtitle>
                <Card.Text id="h3">Bhubaneswar, Odisha 751003</Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="footer-about1">
            <Card style={{ width: "15rem" }} className="footer-about">
              <Card.Body>
                <Card.Title id="h3">Get Social</Card.Title>
                <div className="footer-social">
                  <h3 id="h3">Follows Us</h3>
                  <div className="footer-social--icons">
                    <div>
                      <a href="https://www.facebook.com/Samruddhaodishaparty/">
                        <FaFacebookF className="icons1" />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://instagram.com/samruddhaodisha2022?igshid=MzRlODBiNWFlZA=="
                        target="_blank"
                      >
                        <FaInstagram className="icons2" />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.youtube.com/@samruddhaodisha4462"
                        target="_blank"
                      >
                        <FaYoutube className="icons3" />
                      </a>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <hr className="hr" />
        <div className="footer-bottom--section">

          <div className="container-grid-grid-two-column">
            <p>
              @{new Date().getFullYear()} SamruddhaOdisha. All Rights Reserved
            </p>

            <p>PRIVACY POLICY</p>
            <p>TERMS & CONDITIONS</p>

          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

// install the form
// npm install styled-components@5.3.10
// npm install react-icons --save
// npm install @mui/material @mui/styled-engine-sc styled-components
// npm install @material-ui/core
// npm install --legacy-peer-deps --save radium
// npm install @mui/material --legacy-peer-deps
// npm install @material-ui/core --force
// npm install --save formik --force
// npm i yup --force

// npm install formik --save --force
// npm install @react-navigation/native-stack
// npm install @react-navigation/native
// npm install react-native-screens react-native-safe-area-context