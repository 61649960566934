import { useEffect, useState } from "react";
import React from "react";
import "./Login.css"
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { db, storage } from '../firebase-config'; // Import the firestore instance
import { useHistory } from 'react-router-dom'; // Import useHistory from react-router-dom
import Add from "../../images/addAvatar.png";
import Spinner from 'react-bootstrap/Spinner';
import { cons } from "../../Data/const";
import { GP } from "../../Data/gp";
import Select from 'react-select';


const Login = () => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [otp, setOTP] = useState('');
    const [error, setError] = useState(null);
    const history = useHistory();
    const [name, setName] = useState('');
    const [fname, setfName] = useState('');
    const [Constituency, setCons] = useState('');
    const [gp, setgp] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Add isLoading state
    const [showSignUpMessage, setShowSignUpMessage] = useState(false); // Add state to control the message


    useEffect(() => {
        // Check if a user is already authenticated
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is already authenticated, redirect to dashboard
                history.push('/Volunter'); // Replace with your actual route
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, [history]);


    const handleSendOTP = async () => {

        if (phoneNumber === null) {
            setError("Please Fill in all fields");
            return;
        }
        const formattedPhoneNumber = `+91${phoneNumber}`; // Modify according to your country code
        const isValidMobileNumber = /^\+91\d{10}$/.test(formattedPhoneNumber);

        if (!isValidMobileNumber) {
            setError('Please enter a valid Indian mobile number');
            return;
        }

        setIsLoading(true);

        const userExists = await checkIfUserExists(phoneNumber);

        if (!userExists) {
            // User doesn't exist, show a message to sign up
            setShowSignUpMessage(true);
            setError(''); // Clear any previous errors
            setIsLoading(false);
            return;
        }

        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
                // This will be executed when reCAPTCHA is solved.
            }
        });

        try {

            const confirmationResult = await firebase.auth().signInWithPhoneNumber(
                formattedPhoneNumber,
                recaptchaVerifier
            );
            setVerificationId(confirmationResult.verificationId);
            setError(null); // Clear any previous errors
            setIsLoading(false);
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                // User doesn't exist, show a message to sign up
                setShowSignUpMessage(true);
                setError(''); // Clear any previous errors
            } else {
                console.error(error);
                setError('Failed to send OTP. Please try again.');
            }
            setIsLoading(false);
        }
    };

    const checkIfUserExists = async (formattedPhoneNumber) => {
        try {
            const userRef = firebase.firestore().collection("users");
            const querySnapshot = await userRef.where("phone", "==", formattedPhoneNumber).get();

            if (!querySnapshot.empty) {
                // User with the given phone number exists
                return true;
            } else {
                // User with the given phone number does not exist
                return false;
            }
        } catch (error) {
            throw error; // Handle Firestore query errors
        }
    };

    const handleVerifyOTP = async () => {
        setIsLoading(true);
        try {
            const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
            await firebase.auth().signInWithCredential(credential);
            setError(null); // Clear any previous errors
            setIsLoading(false);
            history.push('/Volunter'); // Replace '/dashboard' with your actual route
            // Redirect to the dashboard page or perform necessary actions
        } catch (error) {
            console.error(error);
            setError('Invalid OTP. Please try again.');
        }
        setIsLoading(false);
    };

    const handleRegSendOTP = async () => {

        if (phoneNumber === "" || name === "" || fname === "" || Constituency === "" || gp === "") {
            setError("Please Fill in all fields");
            return;
        }
        const formattedPhoneNumber = `+91${phoneNumber}`; // Modify according to your country code
        const isValidMobileNumber = /^\+91\d{10}$/.test(formattedPhoneNumber);

        if (!isValidMobileNumber) {
            setError('Please enter a valid Indian mobile number');
            return;
        }

        const userExists = await checkIfUserExists(phoneNumber);

        if (userExists) {
            // User doesn't exist, show a message to sign up
            alert('You have alredy an account');
            setError(''); // Clear any previous errors
            setIsLoading(false);
            return;
        }

        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
                // This will be executed when reCAPTCHA is solved.
            }
        });

        try {
            setIsLoading(true);
            const confirmationResult = await firebase.auth().signInWithPhoneNumber(
                `+91${phoneNumber}`,
                recaptchaVerifier
            );
            setVerificationId(confirmationResult.verificationId);
            setError('');
        } catch (error) {
            console.error(error);
            setError('Failed to send OTP. Please try again.');
        }
        setIsLoading(false);
    };

    const handleRegister = async () => {
        setIsLoading(true);
        try {
            const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
            await firebase.auth().signInWithCredential(credential);

            const newUser = firebase.auth().currentUser;

            // Upload the profile picture if available and get its URL
            let profilePictureUrl = '';
            if (profilePicture) {
                const pictureRef = storage.ref(`profilePictures/${firebase.auth().currentUser.uid}`);
                const pictureSnapshot = await pictureRef.put(profilePicture); // Upload the picture
                profilePictureUrl = await pictureSnapshot.ref.getDownloadURL(); // Get the download URL
            }

            // Use the newUser.uid as the document ID when storing user data in Firestore
            await db.collection('users').doc(newUser.uid).set({
                name: name,
                fname: fname,
                phone: phoneNumber,
                profilePictureUrl: profilePictureUrl,
                constituency: Constituency,
                gp: gp
            });


            localStorage.setItem('authUser', 'true');
            setIsLoading(false);

            history.push('/Volunter'); // Replace '/dashboard' with your actual route

            // Registration successful
        } catch (error) {
            console.error('Error registering user:', error);
            setError('Failed to register. Please try again.');
        }
        setIsLoading(false);
    };


    const handleProfilePictureChange = (e) => {
        if (e.target.files[0]) {
            setProfilePicture(e.target.files[0]);
        }
    };


    const Consoptions = cons.map((option) => ({ value: option, label: option }));
    const Gpoptions = GP.map((option) => ({ value: option, label: option }));


    const [showSignUpForm, setShowSignUpForm] = useState(false); // To toggle the sign-up form
    const [showLoginForm, setShowLoginForm] = useState(true); // To toggle the login form

    // Handler to show the sign-up form and hide the login form
    const showSignUp = () => {
        setShowSignUpForm(true);
        setShowLoginForm(false);
    };

    // Handler to show the login form and hide the sign-up form
    const showLogin = () => {
        setShowLoginForm(true);
        setShowSignUpForm(false);
    };

    return (
        <>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title> meta.codes - Animated Login and Registration Form </title>
            <link rel="stylesheet" href="style.css" />
            <div className="login_body">
                <div className="wrapper">
                    {showSignUpForm && (
                        <div className={`form-wrapper_sign-up ${showSignUpForm ? 'fade-in' : 'fade-out'}`}>

                            <h1>Sign Up</h1>
                            <div>
                                <div className="input-text">
                                    <input type="text" required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Name"
                                    />
                                    {/* <label htmlFor>Name</label> */}

                                </div>
                            </div>
                            <div className="input-text">
                                <input type="text" required
                                    value={fname}
                                    onChange={(e) => setfName(e.target.value)}
                                    placeholder="Father Name"
                                />
                                {/* <label htmlFor>Father Name</label> */}
                            </div>
                            <div className="input-text">

                                <input type="text" required
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Mobile Number"
                                />
                                {/* <label htmlFor>Mobile Number</label> */}
                            </div>

                            <div className="input-block1">
                                <label htmlFor="colors" className="input-label1">
                                    Please select your Concituency
                                </label>
                                <Select
                                    className="chose"
                                    name="constituency"
                                    value={Consoptions.find((option) => option.value === Constituency)}
                                    onChange={(e) => setCons(e.value)}
                                    options={Consoptions}
                                    placeholder="Select a Constituency"
                                />
                            </div>

                            <div className="input-block1">
                                <label htmlFor="colors" className="input-label1">
                                    Please select your Gram Panchayat
                                </label>

                                <Select
                                    className="chose"
                                    name="gp"
                                    value={Gpoptions.find((option) => option.value === gp)}
                                    onChange={(e) => setgp(e.value)}
                                    options={Consoptions}
                                    placeholder="Select a GramPanchayat"
                                />
                            </div>

                            <div className="input-text1">
                                {/* Profile Picture */}
                                <div className="proimg">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfilePictureChange}
                                        placeholder="Enter Your Profile Picture"
                                    />
                                    <label htmlFor>Add Your Photo</label>

                                </div>

                                {profilePicture === '' ? (
                                    <p className="form-error">Upload a Profile Picture</p>
                                ) : null}
                            </div>

                            <div id="recaptcha-container"></div>
                            <button className="signup-button" onClick={handleRegSendOTP}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                Send OTP / Sign Up
                            </button>
                            <p>{error}</p>
                            {verificationId && (
                                <div className="input-text">
                                    <input type="text" value={otp} onChange={(e) => setOTP(e.target.value)} placeholder="OTP" />
                                    <button className="signup-button" type="submit" onClick={handleRegister} >
                                        {isLoading &&

                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                        Sign Up</button>
                                </div>
                            )}

                            <div class="new-members1">
                                Already have an account?
                                <a href="#" className="signIn-link" onClick={showLogin}>
                                    Sign In
                                </a>
                            </div>

                        </div>
                    )}

                    {showLoginForm && (
                        <div className={`form-wrapper_sign-in ${showSignUpForm ? 'fade-out' : 'fade-in'}`}>
                            <div>
                                <h1>Login</h1>
                                <div id="recaptcha-container"></div>
                                <div className="input-text">
                                    <input type="text" required
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder="Phone Number" />

                                </div>

                                {verificationId && (
                                    <div>
                                        <div className="input-text">
                                            <input type="text" required
                                                value={otp} onChange={(e) => setOTP(e.target.value)} placeholder="OTP"
                                            />
                                            {/* <label htmlFor>OTP</label> */}
                                        </div>
                                        <button className="signin-button" onClick={handleVerifyOTP}>
                                            {isLoading &&
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            Verify OTP</button>

                                    </div>

                                )}
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                {showSignUpMessage && (
                                    <p style={{ color: 'red' }}>
                                        You don't have an account. Please sign up first.
                                    </p>
                                )}

                                <button type="submit" className="signin-button"
                                    onClick={handleSendOTP}
                                >
                                    {isLoading &&
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                    Send OTP / Log In</button>


                                <div class="new-members">
                                    Don't have an account?
                                    <a href="#" className="signup-link" onClick={showSignUp}>
                                        Sign up now
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
};

export default Login;
