import React from "react";
import { db } from "../firebase-config.js";
import { useState, useEffect } from "react";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import Modal from "react-modal";
import { getDownloadURL, ref, uploadBytesResumable, getStorage } from "firebase/storage";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "../Admin/Designs/Show.css";
import { Dist } from "../../Data/district.js";
import { GP } from "../../Data/gp.js";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { useAuth } from "../../Context/AuthProvider.js";
import { cons } from "../../Data/const.js";
import Loading from "../../components/Loading.js";



const Show = () => {
  const [task, setTask] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedGP, setSelectedGP] = useState("");
  const [selectedCons, setSelectedCons] = useState("");
  const [selectBlock, setSelectedBlock] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [updatedTaskDetails, setUpdatedTaskDetails] = useState({
    additionalDetails: "",
    photo: null,
  });
  const { currentUser, registerUser, verifyOTPAndSignIn, fetchUserData } = useAuth();


  const [userName, setUserName] = useState('');
  const [userNumber, setNumber] = useState('');
  const [userProfilePic, setProfilePic] = useState('');

  React.useEffect(() => {

    const fetchUserDataForCurrentUser = async () => {
      setIsLoading(true);
      if (currentUser) {
        try {
          const userData = await fetchUserData(currentUser.uid);
          const n = userData && userData.name; // Extract the name field
          setUserName(n);
          const no = userData && userData.phone; // Extract the name field
          setNumber(no);
          const p = userData && userData.profilePictureUrl; // Extract the name field
          setProfilePic(p);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserDataForCurrentUser();
  }, [currentUser, fetchUserData]);


  async function fetch() {
    setIsLoading(true);
    const querySnapshot = await getDocs(collection(db, "VolunteerTask"));
    querySnapshot.forEach((doc) => {
      setTask((cur) => [...cur, { id: doc.id, data: doc.data() }]);
    });

    setIsLoading(false);
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const snapshot = await firebase.database().ref('status').once('value');
      const statusData = snapshot.val();
      if (statusData) {
        const statusArray = Object.values(statusData);
        setData(statusArray);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
    fetchData();
  }, []);

  const filterTasks = () => {
    const filteredTasks = task.filter((element) => {
      const districtMatch = selectedDistrict
        ? (element.data.District && typeof element.data.District === 'string' && element.data.District.split(',').includes(selectedDistrict))
        : true;
      const gpMatch = selectedGP
        ? (element.data.GP && typeof element.data.GP === 'string' && element.data.GP.split(',').includes(selectedGP))
        : true;
      const blockMatch = selectBlock
        ? (element.data.block && typeof element.data.block === 'string' && element.data.block.split(',').includes(selectBlock))
        : true;
      const consMatch = selectedCons
        ? (element.data.Cons && typeof element.data.Cons === 'string' && element.data.Cons.split(',').includes(selectedCons))
        : true;

      return districtMatch && gpMatch && blockMatch && consMatch;
    });

    return filteredTasks;
  };


  const openModal = (task) => {
    setSelectedTask(task);
    setModalIsOpen(true);
  };


  const closeModal = () => {
    setSelectedTask(null);
    setModalIsOpen(false);
    setSelectedStatus('');
    setUpdatedTaskDetails({
      additionalDetails: "",
      photo: null,
    });
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      if (!selectedTask || !selectedStatus) {
        setError("Please fill in all fields");
        return;
      }

      // Check for geolocation availability
      if ('geolocation' in navigator) {
        // Ask for permission to access user's location
        navigator.geolocation.getCurrentPosition(async (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;

          const storage = getStorage();

          let photoURL = selectedTask.data.photoURL;
          if (updatedTaskDetails.photo) {
            const storageRef = ref(storage, `UserScreenshots/${userNumber}`);
            const uploadTask = uploadBytesResumable(storageRef, updatedTaskDetails.photo);
            const uploadSnapshot = await uploadTask;
            photoURL = await getDownloadURL(uploadSnapshot.ref);
          }

          const updatedData = {
            status: selectedStatus,
            additionalDetails: updatedTaskDetails.additionalDetails,
            photoURL: photoURL,
            location: {
              latitude: userLatitude,
              longitude: userLongitude,
            },

            username: userName,
            userphone: userNumber,
            userpic: userProfilePic,
            Taskid: selectedTask.id,
            Tasktitle: selectedTask.data.Title,
            Date: selectedTask.data.Date,
            cons: selectedTask.data.Cons,
            GP: selectedTask.data.GP,
            Dist: selectedTask.data.District,
            cons: selectedTask.data.Cons,
            Block: selectedTask.data.Block,
            Level: selectedTask.data.Level
          };

          await setDoc(doc(db, "TaskUpdate", userNumber), updatedData, { merge: true });

          fetch();
          console.log("5")
          setIsLoading(false);
          closeModal();
        });
      } else {
        alert("Geolocation is not available in this browser.");
      }
    } catch (error) {
      alert("Error updating task:");
      // Handle error state here
    }
  };


  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setUpdatedTaskDetails((prevDetails) => ({
      ...prevDetails,
      photo: file,
    }));
  };

  return (
    <div>
      <div className="heading">
        <h1>Welcome to Task Management System</h1>
      </div>

      <div className="optchose">
        <div>
          <label id="label">District </label>
          <select
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}
          >
            <option value="">All</option>
            {Dist.map((district) => (
              <option key={district.name} value={district.name}>
                {district.name}
              </option>
            ))}
            {/* Add more options for districts */}
          </select>

        </div>
        <div>
          <label id="label">Block </label>
          <select
            value={selectBlock}
            onChange={(e) => setSelectedBlock(e.target.value)}
          >
            <option value="">All</option>
            {Dist.find((district) => district.name === selectedDistrict)?.blocks.map((block) => (
              <option key={block} value={block}>
                {block}
              </option>
            ))}
            {/* Add more options for districts */}
          </select>
        </div>

        <div>
          <label id="label">Grampanchayat </label>
          <select
            value={selectedGP}
            onChange={(e) => setSelectedGP(e.target.value)}
          >
            <option value="">All</option>
            {GP.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
            {/* Add more options for GPs */}
          </select>
        </div>

        <div>
          <label id="label">Consituency </label>
          <select
            value={selectedCons}
            onChange={(e) => setSelectedCons(e.target.value)}
          >
            <option value="">All</option>
            {cons.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      {isLoading ?
        (<Loading />) :
        (filterTasks().map((element, index) => (
          <div className="task_card_container" key={index}>
            <div className="task_card">
              <Card className="text-center" border="danger" variant="flush">
                <Card.Header><h2>TASK - {element.data.id}</h2></Card.Header>
                <Card.Body>
                  <Card.Title><h2>Title - {element.data.Title}</h2></Card.Title>
                  <Card.Text>
                    <h5>Description - {element.data.Description}</h5>

                  </Card.Text>

                </Card.Body>
                <Card.Footer className="text-muted"><Card.Text>
                  <div className="row">
                    <div className="col">
                      <h6>District - {element.data.District}</h6>
                    </div>
                    <div className="col">
                      <h6>Block - {element.data.Block}</h6>
                    </div>
                    <div className="col">
                      <h6>GP - {element.data.GP}</h6>
                    </div>
                    <div className="col">
                      <h6>Consituency - {element.data.Cons}</h6>
                    </div>
                    <div className="col">
                      <h6>Level - {element.data.Level}</h6>
                    </div>
                  </div>
                </Card.Text>

                </Card.Footer>
                <Card.Footer className="text-muted"><Button variant="primary" onClick={() => openModal(element)}>Update</Button></Card.Footer>
                <Card.Footer className="text-muted">Date- {element.data.Date}</Card.Footer>
              </Card>
            </div>
          </div>
        )))}


      {selectedTask && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Update Task">

          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h2 className="h2">Update Task</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
              </div>

              <div class="modal-body">
                <div>
                  <select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    {data.map((statusItem, index) => (
                      <option key={index} value={statusItem.status}>
                        {statusItem.status}
                      </option>
                    ))}
                  </select>
                  {updatedTaskDetails.status === '' ? (
                    <p className="form-error">Enter Status</p>
                  ) : null}
                </div>
                <div className="input-group">
                  <textarea
                    placeholder="Additional Details"
                    value={updatedTaskDetails.additionalDetails}
                    onChange={(e) =>
                      setUpdatedTaskDetails((prevDetails) => ({
                        ...prevDetails,
                        additionalDetails: e.target.value,
                      }))
                    }
                  />
                  {updatedTaskDetails.additionalDetails === '' ? (
                    <p className="form-error">Enter Additional Details</p>
                  ) : null}
                </div>
                <input type="file" onChange={handlePhotoChange} />
              </div>

              <div class="modal-footer">

                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                <button type="button" class="btn btn-primary" onClick={handleUpdate}>Update</button>
                {isLoading && <Loading />}
                {error && (
                  <p>{error}</p>)}
              </div>
            </div>
          </div>
          <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                ...
              </div>
            </div>
          </div>

        </Modal>
      )}

    </div>
  );
};

export default Show;
